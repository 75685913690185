import {PersonIcon, TriangleDownIcon} from '@primer/octicons-react'
import {ActionList, Button} from '@primer/react'
import type React from 'react'
import {useCallback, useMemo} from 'react'
import {getTokensByType, replaceAllFiltersByTypeInSearchQuery, searchUrl} from '../../../utils/urls'
import type {FilterBarPickerProps} from './ListItemsHeaderWithoutBulkActions'
import {useQueryContext} from '../../../contexts/QueryContext'
import {AssigneeRepositoryPicker, type Assignee} from '@github-ui/item-picker/AssigneePicker'
import {LABELS} from '../../../constants/labels'
import {useAppPayload} from '@github-ui/react-core/use-app-payload'
import type {AppPayload} from '../../../types/app-payload'
import {isFeatureEnabled} from '@github-ui/feature-flags'
import {useFeatureFlags} from '@github-ui/react-core/use-feature-flag'
import {AssigneeRepositoryPickerV2} from '@github-ui/item-picker/AssigneePickerV2'
import {formatActorLogin} from '@github-ui/item-picker/utils/assignee-picker'

export function ListAuthorFilter({repo: scopedRepo, applySectionFilter, nested}: FilterBarPickerProps) {
  const {name: repo, owner} = scopedRepo
  const {activeSearchQuery, currentViewId} = useQueryContext()
  const {current_user_settings} = useAppPayload<AppPayload>()
  const includeBots = isFeatureEnabled('issues_react_include_bots_in_pickers')

  const currentAuthorTokens = useMemo(() => getTokensByType(activeSearchQuery, 'author'), [activeSearchQuery])

  const onSelectionChanged = useCallback(
    (selectedAuthors: Assignee[]) => {
      const authors = selectedAuthors.map(formatActorLogin)
      const newQuery = replaceAllFiltersByTypeInSearchQuery(activeSearchQuery, authors, 'author')
      const url = searchUrl({viewId: currentViewId, query: newQuery})
      applySectionFilter(newQuery, url)
    },
    [activeSearchQuery, applySectionFilter, currentViewId],
  )

  const {issues_react_assignee_picker_v2} = useFeatureFlags()

  const props = {
    readonly: false,
    title: LABELS.filters.authorLabel,
    assigneeTokens: currentAuthorTokens,
    assignees: [],
    repo,
    owner,
    onSelectionChange: onSelectionChanged,
    shortcutEnabled: current_user_settings?.use_single_key_shortcut || false,
    anchorElement: nested ? NestedAuthorsAnchor : AuthorsAnchor,
    showNoMatchItem: true,
  }

  if (issues_react_assignee_picker_v2) {
    return (
      <AssigneeRepositoryPickerV2
        name="author"
        selectionVariant="single"
        {...props}
        includeAuthorableBots={includeBots}
        includeAssignableBots={false}
      />
    )
  } else {
    return <AssigneeRepositoryPicker name="author" selectionVariant="single" includeBots={includeBots} {...props} />
  }
}

function NestedAuthorsAnchor(props: React.HTMLAttributes<HTMLElement>) {
  return (
    <ActionList.Item {...props} aria-label={LABELS.filters.authorLabel} role="menuitem">
      <ActionList.LeadingVisual>
        <PersonIcon />
      </ActionList.LeadingVisual>
      {LABELS.filters.author}...
    </ActionList.Item>
  )
}

function AuthorsAnchor(props: React.HTMLAttributes<HTMLButtonElement>) {
  return (
    <Button
      variant="invisible"
      sx={{
        color: 'fg.muted',
        width: 'fit-content',
      }}
      data-testid="authors-anchor-button"
      trailingVisual={TriangleDownIcon}
      aria-label={LABELS.filters.authorLabel}
      {...props}
    >
      {LABELS.filters.author}
    </Button>
  )
}

try{ ListAuthorFilter.displayName ||= 'ListAuthorFilter' } catch {}
try{ NestedAuthorsAnchor.displayName ||= 'NestedAuthorsAnchor' } catch {}
try{ AuthorsAnchor.displayName ||= 'AuthorsAnchor' } catch {}